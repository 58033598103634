<template>
  <div class="product-hero" :style="`background: ${product.colors}`">
    <div class="applet-half half">
      <product-applet
        @click="navigate(product.route)"
        :product="product"
        :logo="product.logo"
        :designText="product.designText"
      />
      <oval-button
        v-if="routable"
        text="Learn More"
        @click="navigate(product.route)"
      />
    </div>
    <div class="title-half half">
      <h2 class="tagline">{{ product.tagline }}</h2>
    </div>
    <!-- <blur-bar /> -->
  </div>
</template>
<script>
import OvalButton from "./OvalButton.vue";
import ProductApplet from "./ProductApplet.vue";
// import BlurBar from "../components/BlurBar.vue";
export default {
  methods: {
    navigate(route) {
      if (this.routable) {
        this.$router.push(route);
      }
    },
  },
  created() {},
  components: { ProductApplet, OvalButton },
  props: ["product", "routable"],
};
</script>
<style scoped>
.product-hero {
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.half {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tagline {
  text-align: right;
  font-size: 30pt;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 60pt;
  margin: 0 50px 50px;
}
@media screen and (min-width: 650px) {
  .product-hero {
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 10%;
  }
}
@media screen and (min-width: 1100px) {
  .product-hero {
    /* padding: 0 200px; */
  }

  .tagline {
    font-size: 35pt;
    line-height: 70pt;
    margin-left: 100px;
  }
}
</style>
