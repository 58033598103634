<template>
  <div id="spin-pricing-page">
    <h1>Oh! One more thing.</h1>
    <h1>It's only $20/mo.</h1>
    <h1>**mic drop**</h1>
    <div class="button-wrapper">
      <a href="https://demo.spindj.io" target="_blank">
        <Button class="rectangular-button" text="Demo!" />
      </a>
      <a href="https://spindj.io/signup" target="_blank">
        <Button class="rectangular-button" text="Sign me up!" />
      </a>
    </div>
  </div>
</template>
<script>
import Button from "../UI/RectangularButton.vue";
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {
    Button,
  },
  props: [],
};
</script>
<style scoped>
#spin-pricing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10%;
  box-sizing: border-box;
}
.button-wrapper {
  display: flex;
  justify-content: center;
}

.rectangular-button {
  margin: 15px;
}

h1 {
  font-weight: 900;
}
@media screen and (min-width: 500px) {
  #spin-pricing-page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10%;
    box-sizing: border-box;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .rectangular-button {
    margin: 15px;
  }

  h1 {
    font-weight: 900;
  }
}
</style>
