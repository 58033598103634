<template>
  <div :class="disabled ? 'rectangular-button disabled' : 'rectangular-button'">
    <h3>{{ text }}</h3>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: ["text", "disabled"],
};
</script>
<style scoped>
.rectangular-button {
  width: fit-content;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 3px;
  display: flex;
  align-items: center;
  color: #3d3d3d;
  box-sizing: border-box;
  cursor: pointer;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
}

.rectangular-button:hover {
  filter: drop-shadow(0px 7px 3px rgba(0, 0, 0, 0.25));
}

.disabled {
  opacity: 0.5;
}

h3 {
  font-weight: 900;
  font-size: 0.75rem;
  margin: 5px 20px;
}
@media screen and (min-width: 500px) {
  .rectangular-button {
    padding: 3px;
  }

  h3 {
    font-size: 1rem;
    margin: 5px 30px;
  }
}
</style>
