<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: [],
};
</script>
<style scoped></style>
