<template>
  <div class="table-wrapper">
    <h5 class="heading">{{ title }}</h5>
    <table>
      <tr v-for="(item, index) in list" :key="index">
        <td :class="index === list.length - 1 ? 'last title' : 'title'">
          <h5>
            {{ item[propertyOne]
            }}{{ item.modifier ? item.modifier.symbol : undefined }}
          </h5>
        </td>
        <td :class="index === list.length - 1 ? 'last svg' : 'svg'">
          <img :src="item[propertyTwo]" alt="" />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: ["list", "propertyOne", "propertyTwo", "title"],
};
</script>
<style scoped>
.table-wrapper {
  display: flex;
  flex-direction: column;
}

.heading {
  margin: 10px;
  font-weight: 900;
  font-size: 12pt;
}
table {
  width: 300px;
  text-align: left;
  overflow: hidden;
  border: 1px solid #494949;
  border-radius: 25px;
  border-spacing: 0;
  box-sizing: border-box;
}
table td {
  padding: 10px;
}

td {
  border-bottom: 1px solid #494949;
  box-sizing: border-box;
}

table:last-child {
  /* border-bottom: none; */
}

.last {
  border-bottom: none;
}

.title {
  border-right: 1px solid #494949;
  width: 225px;
}

h5 {
  margin: 0 15px;
}

.svg {
  height: 100%;
  width: 100px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

img {
  max-height: 20px;
  max-width: 30px;
}
</style>
