<template>
  <div class="product-applet-wrapper">
    <div class="product-applet">
      <img class="product-logo" :src="logo" alt="" />
    </div>
    <img class="design-text" :src="designText" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: ["logo", "designText", "product"],
};
</script>
<style scoped>
.product-applet-wrapper {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-applet {
  cursor: pointer;
  height: 150px;
  width: 150px;
  background-color: white;
  border-radius: 25px;
  filter: drop-shadow(0px 6px 3px rgba(69, 69, 69, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-applet:hover {
  filter: drop-shadow(0px 6px 3px rgba(69, 69, 69, 0.75));
}

.product-logo {
  height: 70%;
}

.design-text {
  cursor: pointer;
  width: 130px;
  margin: 30px 0;
}
</style>
