<template>
  <div id="spin-headphones-page">
    <img src="../../assets/headphone-half.png" alt="headphones" />
    <div id="headphones-half-copy">
      <h1>An invaluable tool for your DJ business</h1>
      <p>
        With tools like automations, contracts, invoices, digital payments, and
        planning tools, Spin is the perfect tool for managing your DJ business.
        We'll handle all of the boring business stuff so you can continue
        scaling businesses and filling dance floors.
        <br /><br /><i>Basically, you're welcome.</i>
      </p>
      <div class="buttons-wrapper">
        <a href="https://demo.spindj.io" target="_blank">
          <Button class="button" text="Check it out" />
        </a>
        <a href="https://spindj.io/signup" target="_blank">
          <Button class="button" text="Sign up" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../UI/RectangularButton.vue";
export default {
  components: {
    Button,
  },
};
</script>
<style scoped>
#spin-headphones-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-evenly;
  position: relative;
}

img {
  max-height: 60vh;
  max-width: 50%;
}

#headphones-half-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  margin: 0 30px;
}

h1 {
  font-weight: 900;
  font-size: 2rem;
}

.buttons-wrapper {
  display: flex;
}

.button {
  margin: 5px;
}
@media screen and (min-width: 500px) {
  #spin-headphones-page {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  img {
    max-width: 40%;
  }

  #headphones-half-copy {
    margin: auto;
    width: 50%;
  }

  h1 {
    font-size: 3rem;
  }
  p {
    font-size: 1.25rem;
  }
}
</style>
