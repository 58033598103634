<template>
  <div class="table-wrapper">
    <h5 class="heading">{{ title }}</h5>
    <table>
      <tr v-for="(item, index) in list" :key="index">
        <td :class="index === list.length - 1 ? 'title last' : 'title'">
          <h5 class="item-text">{{ item }}</h5>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: ["list", "title"],
};
</script>
<style scoped>
.table-wrapper {
  display: flex;
  flex-direction: column;
}

.heading {
  margin: 25px 0 10px 0;
  font-weight: 900;
  font-size: 12pt;
}
table {
  width: 300px;
  text-align: left;
  overflow: hidden;
  border: 1px solid #494949;
  border-radius: 25px;
  border-spacing: 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.5);
}
table td {
  padding: 10px;
  border-bottom: 1px solid #494949;
  box-sizing: border-box;
}

.last {
  border-bottom: none;
}

.item-text {
  margin: 0 15px;
  text-align: center;
}
</style>
