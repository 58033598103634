<template>
  <div id="home-products-scroll-wrapper">
    <product-hero
      class="product-hero"
      v-for="(product, index) in products"
      :key="index"
      :routable="true"
      :product="product"
    />
  </div>
</template>
<script>
import SpinLogo from "../assets/SpinLogo.svg";
import SpinText from "../assets/SpinText.svg";
import ProductHero from "../components/UI/ProductHero.vue";
export default {
  data() {
    return {
      products: [
        {
          name: "Spin",
          logo: SpinLogo,
          designText: SpinText,
          route: "/spin",
          tagline: "Software made by DJs for DJs",
          colors: "linear-gradient(100deg, #FFD2B1, #FFAAAA)",
        },
      ],
    };
  },
  components: { ProductHero },
  props: [],
};
</script>
<style scoped>
#home-products-scroll-wrapper {
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
}

.product-hero {
  scroll-snap-align: end;
}
</style>
