<template>
  <div id="spin-page">
    <vinyl-record class="spin-page-section" />
    <headphones class="spin-page-section" />
    <features-list class="spin-page-section" />
    <turntable class="spin-page-section" />
    <we-built-a-demo class="spin-page-section" />
    <pricing class="spin-page-section" />
  </div>
</template>
<script>
import VinylRecord from "../components/Spin/VinylRecord.vue";
import Headphones from "../components/Spin/Headphones.vue";
import FeaturesList from "../components/Spin/Features/FeaturesList.vue";
import Turntable from "../components/Spin/Turntable.vue";
import WeBuiltADemo from "../components/Spin/WeBuiltADemo.vue";
import Pricing from "../components/Spin/Pricing.vue";

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {
    VinylRecord,
    Headphones,
    FeaturesList,
    Turntable,
    WeBuiltADemo,
    // FoundersClub,
    Pricing,
  },
  props: [],
};
</script>
<style scoped>
#spin-page {
  padding-top: 45px;
  box-sizing: border-box;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  background-color: #e9edf0;
}

.spin-page-section {
  scroll-snap-align: end;
  min-height: 100vh;
}

p {
  font-size: 1.25rem;
}
</style>
