<template>
  <div class="features-list-wrapper">
    <div class="features-charts">
      <div class="column-one">
        <two-column-chart
          title="Limits"
          :list="limits"
          propertyOne="name"
          propertyTwo="svg"
        />
        <one-column-chart
          :list="developmentFeatures"
          title="Features in Development"
        />
      </div>
      <div class="column-two">
        <two-column-chart
          title="Features"
          :list="features"
          propertyOne="name"
          propertyTwo="svg"
        />
      </div>
    </div>
    <div class="modifier-text">
      <p v-for="(modifier, index) in modifiers" :key="index" class="modifier">
        {{ modifier.symbol + " " + modifier.text }}
      </p>
    </div>
    <div class="button-section">
      <a href="https://demo.spindj.io" target="_blank">
        <Button class="rectangular-button" text="Check it out!" />
      </a>
      <a href="https://spindj.io/signup" target="_blank">
        <Button class="rectangular-button" text="Sign Up" />
      </a>
    </div>
  </div>
</template>
<script>
import OneColumnChart from "./OneColumnChart.vue";
import TwoColumnChart from "./TwoColumnChart.vue";
import Check from "../../../assets/circle-checkmark.svg";
import infinity from "../../../assets/infinity.svg";
import Button from "../../UI/RectangularButton.vue";
export default {
  data() {
    return {
      features: [
        {
          name: "Customized Branding",
          svg: Check,
        },
        {
          name: "Email Automations",
          svg: Check,
        },
        {
          name: "On-Platform Messaging",
          svg: Check,
        },
        {
          name: "Contract E-Signing",
          svg: Check,
        },
        {
          name: "Payment Integrations",
          svg: Check,
          modifier: {
            symbol: "***",
            text: "Spin integrates with Stripe for payment processing, though additional integrations are scheduled to be developed.",
          },
        },
        {
          name: "One-Off Emails",
          svg: Check,
        },
        {
          name: "Modern Client Portal",
          svg: Check,
        },
        {
          name: "Custom Forms",
          svg: Check,
        },
        {
          name: "Custom Packages, Services and Add-Ons",
          svg: Check,
        },
        {
          name: "Calendar Subscriptions",
          svg: Check,
        },
        {
          name: "Visual Charts",
          svg: Check,
        },
        {
          name: "Multiple Event Contacts",
          svg: Check,
        },
        {
          name: "Multiple Contact Events",
          svg: Check,
        },
        {
          name: "iTunes Library Import",
          svg: Check,
        },
        {
          name: "iTunes Playlist Export",
          svg: Check,
        },
        {
          name: "Spotify Playlist Import",
          svg: Check,
        },
        {
          name: "Regular Updates",
          svg: Check,
        },
        {
          name: "Tech Support",
          svg: Check,
        },
        {
          name: "Facebook User Group",
          svg: Check,
        },
        {
          name: "Mobile Optimized",
          svg: Check,
        },
        {
          name: "Quick Proposals",
          svg: Check,
        },
      ],
      limits: [
        {
          name: "Team Members",
          svg: infinity,
        },
        {
          name: "Clients",
          svg: infinity,
        },
        {
          name: "Vendors",
          svg: infinity,
        },
        {
          name: "Organizers",
          svg: infinity,
        },
        {
          name: "Events",
          svg: infinity,
        },
        {
          name: "Library Tracks",
          modifier: {
            symbol: "*",
            text: "Spin does not limit the amount of tracks you may have in your library, but page load speed could be harmed with significantly larger libraries.",
          },
          svg: infinity,
        },
        {
          name: "Automations",
          svg: infinity,
        },
        {
          name: "Emails Sent",
          modifier: {
            symbol: "**",
            text: "Spin does not currently store sent emails, though it is a feature that is in planning and development.",
          },
          svg: infinity,
        },
        {
          name: "Transactions",
          svg: infinity,
        },
        {
          name: "Support Requests",
          svg: infinity,
        },
      ],
      developmentFeatures: [
        "Email Suite and Storage",
        "Employee Management",
        "Income Reports",
        "Additional Payment Integrations",
        "Non-Event Calendar Items",
        "Equipment Manager",
        "Custom Subdomains",
        "Connect to other SCS Event Industry Apps",
        "Live Song Requests",
        "Native Mobile App",
        "Multi-Day Events",
        "Repeat Events",
        "Timeline Tools",
        "Day-Of, One-Click Print",
      ],
    };
  },
  computed: {
    modifiers() {
      let array = [...this.features, ...this.limits].filter((x) => {
        return x.modifier;
      });
      return array.map((x) => {
        return x.modifier;
      });
    },
  },
  methods: {},
  created() {},
  components: { TwoColumnChart, OneColumnChart, Button },
  props: [],
};
</script>
<style scoped>
.features-list-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-top: 100px;
  /* padding: 100px 100px 0 100px; */
}

.features-charts {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
}

.modifier-text {
  padding: 40px;
  font-size: 8pt;
}

.button-section {
  display: flex;
  justify-content: center;
}

.rectangular-button {
  margin: 10px;
}

@media screen and (min-width: 800px) {
  .features-list-wrapper {
    padding: 100px 100px 0 100px;
  }

  .features-charts {
    flex-direction: row;
    align-items: unset;
    /* align-items: center; */
  }

  .modifier-text {
    padding: 40px 0;
  }
}
</style>
