<template>
  <div class="oval-button">
    <h4>
      {{ text }}
    </h4>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: ["text"],
};
</script>
<style scoped>
.oval-button {
  background-color: white;
  filter: drop-shadow(0px 3px 3px rgba(69, 69, 69, 0.5));
  border: 1px solid rgb(69, 69, 69);
  padding: 15px 15px;
  border-radius: 30px;
  cursor: pointer;
}

.oval-button:hover {
  filter: drop-shadow(0px 3px 3px rgba(69, 69, 69, 0.75));
}

h4 {
  margin: 0;
  /* font-size: 10pt; */
}
@media screen and (min-width: 700px) {
  .oval-button {
    padding: 15px 30px;
    border-radius: 30px;
  }

  h4 {
    margin: 0;
  }
}
</style>
