<template>
  <div id="site-header">
    <div class="logo-wrapper">
      <router-link to="/" tag="div" id="simple-city-logo-wrapper" class="logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="253.81"
          width="30"
          viewBox="0 0 150.673 253.81"
          id="simple-city-software-logo"
          v-if="location !== 'Spin'"
        >
          <path
            id="Path_19"
            data-name="Path 19"
            d="M24.9,226.24,1.75,214.182,1.5,99.132l23.4-3.9Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M24.9,226.24,1.75,214.182,1.5,99.132l23.4-3.9Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#050605"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M24.9,226.24l23.135-7.629.26-120.479L24.9,95.236Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_22"
            data-name="Path 22"
            d="M24.9,226.24l23.135-7.629.26-120.479L24.9,95.236Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#050605"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M126,226.284V59.382l-23.145,5.212V218.038Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M126,226.284V59.382l-23.145,5.212V218.038Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#050605"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M126.029,226.284V59.382l23.145,8.212V214.126Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_26"
            data-name="Path 26"
            d="M126.029,226.284V59.382l23.145,8.212V214.126Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#050605"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M38.984,233.5V20.333L75.235,1.5l36.2,18.87.127,213.077L75.235,252.31Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M38.984,233.5V20.333L75.235,1.5l36.2,18.87.127,213.077L75.235,252.31Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#050605"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M24.9,226.24,1.75,214.182,1.5,99.132l23.4-3.9Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M24.9,226.24,1.75,214.182,1.5,99.132l23.4-3.9Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#050605"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_31"
            data-name="Path 31"
            d="M75.235,252.31v0Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <line
            id="Line_11"
            data-name="Line 11"
            y1="250.81"
            transform="translate(75.235 1.5)"
            fill="none"
            stroke="#050605"
            stroke-miterlimit="10"
            stroke-width="3"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="95"
          width="150"
          class="logo"
          viewBox="0 0 283 95"
          id="simple-city-software-text"
          v-if="location !== 'Spin'"
        >
          <g
            id="Group_118"
            data-name="Group 118"
            transform="translate(256 788)"
          >
            <text
              id="SIMPLE"
              transform="translate(-103 -750)"
              fill="#454545"
              font-size="38"
              font-family="Avenir-Heavy, Avenir"
              font-weight="800"
              letter-spacing="0.1em"
            >
              <tspan x="-152.722" y="0">SIMPLE</tspan>
            </text>
            <text
              id="CITY"
              transform="translate(26 -750)"
              fill="#454545"
              font-size="38"
              font-family="Avenir-Light, Avenir"
              font-weight="300"
              letter-spacing="0.1em"
            >
              <tspan x="-91.656" y="0">CITY</tspan>
            </text>
          </g>
          <text
            id="SOFTWARE"
            transform="translate(0 81)"
            fill="#454545"
            font-size="38"
            font-family="Avenir-Medium, Avenir"
            font-weight="500"
            letter-spacing="0.288em"
          >
            <tspan x="0" y="0">SOFTWARE</tspan>
          </text>
        </svg>
      </router-link>
      <svg
        v-if="location === 'Spin'"
        height="56"
        width="150"
        viewBox="0 0 251 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="spin-logo"
        class="logo"
      >
        <path
          d="M3.94187 37.4713C1.7184 35.3584 0.424639 32.4481 0.345195 29.3806C0.26575 26.3131 1.40714 23.3396 3.51826 21.1143L23.5502 0C25.7737 2.1129 27.0673 5.02321 27.1467 8.09072C27.2261 11.1582 26.0847 14.1317 23.9735 16.3569L3.94187 37.4713Z"
          fill="#3D3D3D"
        />
        <path
          d="M3.60036 55.947C1.37694 53.8341 0.0832581 50.9238 0.00387963 47.8563C-0.0754988 44.7888 1.06593 41.8153 3.17709 39.5901L23.208 18.4747C24.3091 19.5208 25.1934 20.7737 25.8106 22.1619C26.4278 23.5501 26.7657 25.0464 26.805 26.5653C26.8443 28.0842 26.5843 29.596 26.0398 31.0144C25.4954 32.4327 24.6771 33.7298 23.6317 34.8316L3.60036 55.946V55.947Z"
          fill="#3D3D3D"
        />
        <path
          d="M62.4908 45.5808C60.1797 44.9768 57.9997 43.9517 56.0599 42.5568L58.9008 36.25C60.584 37.4553 62.4507 38.3806 64.4287 38.9902C66.4967 39.6696 68.6588 40.0186 70.8354 40.0243C73.2112 40.0243 74.9673 39.671 76.1037 38.9644C76.6164 38.6937 77.0459 38.2886 77.3461 37.7924C77.6464 37.2962 77.8062 36.7277 77.8084 36.1476C77.8152 35.7368 77.7279 35.3298 77.5534 34.9579C77.3789 34.586 77.1216 34.2591 76.8013 34.002C76.0368 33.383 75.1599 32.9178 74.2189 32.6319C73.1679 32.2881 71.7475 31.9091 69.9576 31.4948C67.6648 30.976 65.4052 30.3198 63.1911 29.5295C61.441 28.8803 59.8854 27.795 58.6714 26.3762C57.4137 24.9286 56.7853 22.999 56.786 20.5873C56.7653 18.5546 57.3596 16.5632 58.4907 14.8747C59.7727 13.0503 61.5587 11.64 63.6298 10.8165C66.2957 9.74683 69.1526 9.23651 72.0237 9.31709C74.3045 9.31427 76.577 9.59182 78.7902 10.1435C80.8311 10.628 82.7833 11.4304 84.5754 12.5214L81.993 18.8786C78.9593 17.0686 75.5037 16.088 71.9722 16.0348C69.6304 16.0348 67.9 16.4139 66.7811 17.172C66.2654 17.4832 65.8395 17.9232 65.5452 18.449C65.251 18.9748 65.0985 19.5682 65.1028 20.1708C65.1028 21.413 65.7484 22.335 67.0396 22.9367C68.9452 23.7308 70.9267 24.3283 72.9535 24.72C75.246 25.2398 77.5056 25.896 79.72 26.6852C81.4599 27.3295 83.0128 28.3961 84.2392 29.7893C85.4961 31.2036 86.1245 33.1166 86.1245 35.5283C86.1387 37.5478 85.5342 39.5231 84.3925 41.1883C83.0885 43.0123 81.2859 44.4205 79.2014 45.2438C76.5262 46.3117 73.6614 46.822 70.7823 46.7432C67.9773 46.7513 65.1855 46.3599 62.4908 45.5808Z"
          fill="#3D3D3D"
        />
        <path
          d="M176.716 9.93634H185.083V46.1217H176.716V9.93634Z"
          fill="#3D3D3D"
        />
        <path
          d="M139.763 11.541C141.984 12.493 143.878 14.0741 145.213 16.0897C146.464 18.1975 147.125 20.6041 147.125 23.0559C147.125 25.5078 146.464 27.9144 145.213 30.0222C143.888 32.0468 141.991 33.6304 139.763 34.5709C137.124 35.6775 134.282 36.215 131.421 36.1481H124.138V46.1217H115.771V9.93637H131.421C134.284 9.86995 137.129 10.4171 139.763 11.541ZM136.691 27.6958C137.999 26.61 138.653 25.0678 138.654 23.0694C138.654 21.0351 137.999 19.4754 136.691 18.3903C135.382 17.3052 133.471 16.7623 130.957 16.7616H124.139V29.3235H130.957C133.47 29.3228 135.381 28.7797 136.691 27.6942V27.6958Z"
          fill="#3D3D3D"
        />
        <path
          d="M251 9.93634V46.1217H244.13L226.104 24.1511V46.1217H217.839V9.93634H224.76L242.736 31.9074V9.93634H251Z"
          fill="#3D3D3D"
        />
      </svg>
    </div>
    <a
      href="https://spindj.io/signup"
      target="_blank"
      v-if="location === 'Spin'"
    >
      <Button id="header-signup-button" text="Sign Up" />
    </a>
  </div>
</template>
<script>
import Button from "../components/UI/RectangularButton.vue";
export default {
  computed: {
    location() {
      return this.$route.name;
    },
  },
  components: {
    Button,
  },
};
</script>
<style scoped>
#site-header {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}

.logo-wrapper {
  max-height: 45px;
  display: flex;
}
.logo {
  /* max-height: 30px; */
  height: 45px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
}

a {
  margin: 0;
}

#simple-city-software-logo {
  max-height: 100%;
}
#simple-city-software-text {
  max-height: 100%;
}

@media screen and (min-width: 500px) {
  #site-header {
    padding: 20px 40px;
    box-sizing: border-box;
  }

  .logo-wrapper {
    max-height: 45px;
    display: flex;
  }
  .logo {
    max-height: 45px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  a {
    margin: 0;
  }

  #simple-city-software-logo {
    max-height: 100%;
    /* width: fit-content; */
  }
  #simple-city-software-text {
    max-height: 100%;
  }
}
</style>
