<template>
  <div id="spin-turntable-page">
    <div id="turntable-half-copy">
      <h1>Built for now, <br />building for then</h1>
      <p>
        Spin started as an idea: what if the people that use the software could
        have more influence over the direction and features? Spin’s users have
        direct access to the developers without a filter of HR. It’s like
        building your own CRM without building your own CRM.
      </p>
      <div class="buttons-wrapper">
        <a href="https://demo.spindj.io" target="_blank">
          <Button class="button" text="Check it out" />
        </a>
        <a href="https://spindj.io/signup" target="_blank">
          <Button class="button" text="Sign up" />
        </a>
      </div>
    </div>
    <img src="../../assets/turntable.png" alt="turntable" />
  </div>
</template>

<script>
import Button from "../UI/RectangularButton.vue";
export default {
  components: {
    Button,
  },
};
</script>
<style scoped>
#spin-turntable-page {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  position: relative;
}

img {
  max-height: 60%;
  max-width: 50%;
}

#turntable-half-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin: 30px;
}

h1 {
  font-weight: 900;
  font-size: 2rem;
}

.buttons-wrapper {
  display: flex;
}

.button {
  margin: 5px;
}
@media screen and (min-width: 800px) {
  #spin-turntable-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  img {
    max-height: 60%;
    max-width: 30%;
  }

  p {
    font-size: 1.25rem;
  }

  #turntable-half-copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin: auto;
    /* margin: 10% 10% 10% 10%; */
    width: 50%;
  }

  h1 {
    font-weight: 900;
    font-size: 3rem;
  }

  .buttons-wrapper {
    display: flex;
  }

  .button {
    margin: 5px;
  }
}
</style>
